import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  STORE_INFO_REQUEST,
  STORE_INFO_SUCCESS,
  STORE_INFO_FAILED,
  STORE_INFO_CREATE,
  STORE_INFO_CREATE_SUCCESS,
  STORE_INFO_CREATE_FAILED,
  STORE_INFO_DELETE,
  STORE_INFO_DELETE_SUCCESS,
  STORE_INFO_DELETE_FAILED,
  STORE_INFO_EDIT,
  STORE_INFO_EDIT_SUCCESS,
  STORE_INFO_EDIT_FAILED,
  STORE_INFO_IMPORT_CUSTOMER_REQUEST,
  STORE_INFO_IMPORT_CUSTOMER_SUCCESS,
  STORE_INFO_IMPORT_CUSTOMER_FAILED, UPDATE_STORE_LOGO_SUCCESS,
  UPDATE_STORE_LOGO_FAILED,
  UPDATE_STORE_LOGO_CREATE
} from "../../../_utils/constants/admin/StoreInfo";

function* fetchStoreInfo(action) {
  try {
    const response = yield call(axios.get, `${API_URL}/Store/api/Store/GetStore`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: STORE_INFO_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: STORE_INFO_FAILED, payload: error.response });
    }
  }
}

function* createStoreInfo(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Store/api/Store/CreateStore`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: STORE_INFO_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: STORE_INFO_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* updatestoreLogo(action) {
  try {
    const formData = new FormData();

    Object.entries(action.payload.data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = yield call(
      axios.post,
      `${API_URL}/Store/api/Store/UpdateStoreLogo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`, 
          "Content-Type": "multipart/form-data", 
        },
    }
    );
    yield put({
      type: UPDATE_STORE_LOGO_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: UPDATE_STORE_LOGO_FAILED,
        payload: error.response
      });
    }
  }}

function* editStoreInfo(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Store/api/Store/UpdateStore`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: STORE_INFO_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: STORE_INFO_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}

function* deleteStoreInfo(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Store/api/Store/RemoveStore?StoreId=${encodeURIComponent(action.payload.StoreId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: STORE_INFO_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: STORE_INFO_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

function* importCustomersData(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/Api/user/importCustomer`,
      action.data.data, {
      headers: {
        Authorization: `Bearer ${action.data.token}`,
        'Content-Type': 'multipart/form-data'
      },
    }
    );
    yield put({
      type: STORE_INFO_IMPORT_CUSTOMER_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: STORE_INFO_IMPORT_CUSTOMER_FAILED,
        payload: error.response
      });
    }
  }
}

export default function* rootStoreInfo() {
  yield all([
    takeLatest(STORE_INFO_REQUEST, fetchStoreInfo),
    takeLatest(STORE_INFO_CREATE, createStoreInfo),
    takeLatest(STORE_INFO_DELETE, deleteStoreInfo),
    takeLatest(STORE_INFO_EDIT, editStoreInfo),
    takeLatest(STORE_INFO_IMPORT_CUSTOMER_REQUEST, importCustomersData),
    takeLatest(UPDATE_STORE_LOGO_CREATE,updatestoreLogo)
  ])
}