export const STORE_INFO_REQUEST = "@STORE_INFO_REQUEST";
export const STORE_INFO_SUCCESS = "@STORE_INFO_SUCCESS";
export const STORE_INFO_FAILED = "@STORE_INFO_FAILED";

export const STORE_INFO_CREATE = "@STORE_INFO_CREATE";
export const STORE_INFO_CREATE_SUCCESS = "@STORE_INFO_CREATE_SUCCESS";
export const STORE_INFO_CREATE_FAILED = "@STORE_INFO_CREATE_FAILED";

export const UPDATE_STORE_LOGO_CREATE = "@UPDATE_STORE_LOGO_CREATE";
export const UPDATE_STORE_LOGO_SUCCESS = "@UPDATE_STORE_LOGO_SUCCESS";
export const UPDATE_STORE_LOGO_FAILED = "@UPDATE_STORE_LOGO_FAILED";

export const STORE_INFO_DELETE = "@STORE_INFO_DELETE";
export const STORE_INFO_DELETE_SUCCESS = "@STORE_INFO_DELETE_SUCCESS";
export const STORE_INFO_DELETE_FAILED = "@STORE_INFO_DELETE_FAILED";

export const STORE_INFO_EDIT = "@STORE_INFO_EDIT";
export const STORE_INFO_EDIT_SUCCESS = "@STORE_INFO_EDIT_SUCCESS";
export const STORE_INFO_EDIT_FAILED = "@STORE_INFO_EDIT_FAILED";

export const STORE_INFO_IMPORT_CUSTOMER_REQUEST = "@STORE_INFO_IMPORT_CUSTOMER_REQUEST";
export const STORE_INFO_IMPORT_CUSTOMER_SUCCESS = "@STORE_INFO_IMPORT_CUSTOMER_SUCCESS";
export const STORE_INFO_IMPORT_CUSTOMER_FAILED = "@STORE_INFO_IMPORT_CUSTOMER_FAILED";

export const STORE_INFO_STATE_CHANGE = "@STORE_INFO_STATE_CHANGE";